@import "https://fonts.googleapis.com/css?family=Roboto&display=swap";
:root {
  --brand-color-blue: #2ee5c6;
  --brand-color-purple: #8366ff;
  --primary-text-color: #fafafa;
  --secondary-text-color: #ececec;
  --background-black: #0e0e0e;
  --background-grey: #141414;
}

html, body {
  background-color: var(--background-black);
  color: var(--secondary-text-color);
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
}

main {
  letter-spacing: .05em;
  margin: 10%;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

section {
  width: 100%;
}

section:not(:first-of-type) {
  margin-bottom: 40px;
}

#background {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.callout {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  height: 100vh;
  display: flex;
}

.callout__header {
  color: var(--primary-text-color);
  font-size: 48px;
  line-height: 65px;
}

.callout__button {
  max-width: 300px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.about-me .about-me__development {
  margin-bottom: 40px;
}

.about-me .about-me__personal {
  font-size: 14px;
}

.experience-list {
  list-style: none;
}

.experience-list .experience-list__list-header {
  border-bottom: 2px solid var(--brand-color-purple);
  letter-spacing: .1em;
  margin-bottom: 10px;
  font-size: 18px;
  display: inline-block;
}

.experience-list p {
  margin-bottom: 10px;
}

.experience-list p:last-of-type {
  margin-bottom: 25px;
}

.technologies__list li:before {
  content: "-";
  margin-right: 5px;
}

.technologies__list {
  margin-bottom: 20px;
}

.technologies__more {
  transition: height 1s;
  overflow: hidden;
}

.technologies__more .technologies__more-intro-paragraph {
  margin-bottom: 20px;
}

.contact-me {
  justify-content: space-between;
  max-width: 450px;
  margin: 20px auto 80px;
  display: flex;
}

.contact-me span {
  font-size: 32px;
}

.text--normal-spaced {
  letter-spacing: normal;
}

.text--bold {
  font-weight: 700;
}

.text--blue {
  color: var(--brand-color-blue);
}

.text--purple {
  color: var(--brand-color-purple);
}

.section-header {
  color: var(--primary-text-color);
  letter-spacing: .1em;
  font-size: 24px;
  line-height: 65px;
  display: inline-block;
}

.section-header:before {
  border-top: 2px solid var(--brand-color-blue);
  content: "";
  margin-left: -31%;
  display: block;
}

.button--blue {
  border: var(--brand-color-blue) solid 2px;
  color: var(--brand-color-blue);
  text-align: center;
  background-color: #0000;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 16px;
  line-height: normal;
  text-decoration: none;
}

.button--icon span {
  font-size: 24px;
  position: relative;
  top: 3px;
}

.full-width {
  width: 100%;
}

.link {
  color: var(--brand-color-blue);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.hidden, .toggle, .toggle:not(:checked) + .toggle-controls > .toggle-on {
  display: none;
}

.toggle:checked + .toggle-controls > .toggle-on, .toggle:not(:checked) + .toggle-controls > .toggle-off {
  display: block;
}

.toggle:checked + .toggle-controls > .toggle-off {
  display: none;
}

.toggle:not(:checked) + .toggle-controls > .toggle__container {
  height: 0;
  display: none;
}

.toggle:checked + .toggle-controls > .toggle__container {
  display: block;
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  .mobile {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .mobile, .tablet {
    display: none;
  }
}

/*# sourceMappingURL=index.688d49d3.css.map */
